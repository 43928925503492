import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import Icon from '@material-tailwind/react/Icon';
import H6 from '@material-tailwind/react/Heading6';
import { getStorage } from 'utils/auth';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { logoutSuccess } from '../stores/actions/userAction';
import Swal from 'sweetalert2';
import { BsGift } from 'react-icons/bs';

export default function Sidebar() {
    const dispatch = useDispatch()
    const [showSidebar, setShowSidebar] = useState('-left-64');

    const isUser = getStorage()
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
    let receptionist = String(isUser?.role?.[0]).toLocaleLowerCase() === 'receptionist'
    let doctor = String(isUser?.role?.[0]).toLocaleLowerCase() === 'doctor'
    const handleLogout = () => {
        Swal.fire({
            title: "Logout",
            text: "Kamu yakin ingin keluar?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Keluar"
        }).then((result) => {
            if (result?.isConfirmed) {
                Cookies.remove("token")
                Cookies.remove("user")
                dispatch(logoutSuccess())
                window.location.replace('/')
            }
        });
    };

    return (
        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <a
                        href="/"
                        target="_blank"
                        rel="noreferrer"
                        className="mt-2 text-center w-full inline-block"
                    >
                        <H6 color="gray">{isUser?.name}</H6>
                    </a>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                           
                            {admin ?
                                <>
                             
                            <li className="rounded-lg mb-4">
                                <NavLink
                                    to="/"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="dashboard" size="2xl" />
                                    Dashboard
                                </NavLink>
                              
                            </li>
                            <li className="rounded-lg mb-4">
                                <NavLink
                                    to="/friday"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <BsGift size={20}/>
                                    Friday
                                </NavLink>
                              
                            </li>
                            <li className='rounded-lg mb-4'>
                            <NavLink
                                    to="/galleries"
                                    
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="photo" size="2xl" />
                                    Gallery
                                </NavLink>
                            </li>
                                <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/layanan"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="list_alt" size="2xl" />
                                            Layanan
                                        </NavLink>
                                    </li>
                                    <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/category-article"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="article" size="2xl" />
                                            Kategori Artikel
                                        </NavLink>
                                    </li>
                                    <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/article"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="article" size="2xl" />
                                            Artikel
                                        </NavLink>
                                    </li>
                                    <li className="rounded-lg mb-4 relative">
                                        <button
                                            onClick={toggleDropdown}
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg w-full"
                                        >
                                            <Icon name="settings" size="2xl" />
                                            Settings >
                                        </button>
                                        {isOpen && (
                                            <ul className="absolute left-0 bg-white rounded-lg shadow-lg mt-2 w-full">
                                                <li className="rounded-lg">
                                                    <NavLink
                                                        to="/settings"
                                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                                        activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                                    >
                                                        <Icon name="settings" size="2xl" />
                                                        General Settings
                                                    </NavLink>
                                                </li>
                                                <li className="rounded-lg">
                                                    <NavLink
                                                        to="/testimoni"
                                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                                        activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                                    >
                                                        <Icon name="chat" size="2xl" />
                                                        Testimoni
                                                    </NavLink>
                                                </li>
                                                <li className="rounded-lg">
                                                    <NavLink
                                                        to="/partner"
                                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                                        activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                                    >
                                                        <Icon name="group" size="2xl" />
                                                        Partner
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                 
                                    <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/doctors"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="medication" size="2xl" />
                                            Users
                                        </NavLink>
                                    </li>
                                </>
                                
                                :
                            doctor ? 
                            <>
                            <li className="rounded-lg mb-2 ">
                                    <NavLink
                                        to="/schedule"
                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                        activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                    >
                                        <Icon name="list" size="2xl" />
                                        Jadwal
                                    </NavLink>
                                </li>
                             
                                <li className="rounded-lg mb-2 ">
                                        <NavLink
                                            to="/article"
                                            className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                            activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        >
                                            <Icon name="article" size="2xl" />
                                            Artikel
                                        </NavLink>
                                    </li>
                            </>
                            :
                            <></>                                

                            }
                           

                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/profile"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="account_circle" size="2xl" />
                                    Profile
                                </NavLink>
                            </li>
                           
                            <li className="rounded-lg mb-2 text-gray-700">
                                <NavLink
                                    to="/reservasi"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-lg"
                                    activeClassName="bg-teal-500 from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                    <Icon name="book" size="2xl" />
                                    Reservasi
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <NavLink
                                    to="#"
                                    onClick={handleLogout}
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-4 py-3 rounded-l cursor-pointer active:bg-teal-500 from-light-blue-500 to-light-blue-700"
                                    activeClassName=""
                                >
                                    <Icon name="logout" size="2xl" />
                                    Logout
                                </NavLink>
                            </li>
                            
                        </ul>


                    </div>
                </div>
            </div >
        </>
    );
}
