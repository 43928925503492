import { Card, Input, Button, Textarea } from "@material-tailwind/react";
import { useState } from "react";
import { swal } from "../../utils/alert";
import { createTestimoni } from "../../services/testimoni";

export function FormTestimoni() {

    const [formData, setFormData] = useState({
        name: '',
        job: '',
        description: '',
        image: null,
    });

    const [loading, setLoading] = useState(false)
    const handleInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.articleImage) {
            swal.error('Gambar tidak boleh kosong');
            return; // Stop function execution if image is not provided
        }
    
        setLoading(true)
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('job', formData.job);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('image', formData.articleImage);
        // formDataToSend.append('file', formData.articleFile);

        return await createTestimoni(formDataToSend)
        .then(res => {
            console.log(res,'ress')
            if(res.statusCode === 201){
                swal.success('Data berhasil terbuat')
                setTimeout(() => {
                    window.location.href = '/testimoni'
                }, 1000);
            }
        }).catch(err => {
            console.log(err)
        })


    };


    return (
        <Card color="transparent" shadow={false}>
            <h2 color="blue-gray">
                Buat Testimoni
            </h2>
            <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={handleSubmit}>
                <div className="mb-1 flex flex-col gap-6 w-full">
                    <p variant="h6" color="blue-gray" className="">
                        Name
                    </p>
                    <Input
                        size="lg"
                        placeholder="Enter name"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleInputChange}
                        name="name" // Add name attribute
                    />
                    <p variant="h6" color="blue-gray" className="">
                        Job
                    </p>

                    <Input
                        size="lg"
                        placeholder="Enter Job"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleInputChange}
                        name="job" // Add name attribute
                    />
                    <p variant="h6" color="blue-gray" className="">
                        Testimoni
                    </p>
                     <Input
                        size="lg"
                        placeholder="Masukan Testimoni"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleInputChange}
                        name="description" // Add name attribute
                    />
                    <p variant="h6" color="blue-gray">
                        Image
                    </p>
                    <Input
                        type="file"
                        size="lg"
                        accept=".jpg, .jpeg, .webp, png" // Accept only JPG and JPEG files
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleImage" // Add name attribute

                    />
                    
                </div>
                <Button className="mt-6" fullWidth>
                    Add Article
                </Button>
            </form>
        </Card>
    );
}
