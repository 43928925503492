import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import { BsPencil, BsPlus, BsTrash } from 'react-icons/bs';
import { Button } from '@material-tailwind/react';
import { useState } from 'react';
import Swal from 'sweetalert2';
import Modal from './ModalArticleCategory'; // Import modal component
import { formatISODate } from '../../utils/date';

export default function TableCategory({ data, onDelete, onEdit, onAdd }) {
  const [showForm, setShowForm] = useState(false);

  const handleDelete = (uuid) => {
    // SweetAlert for confirmation
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(uuid);
        Swal.fire('Deleted!', 'The category has been deleted.', 'success');
      }
    });
  };

  return (
    <>
      {!showForm ? (
        <Card>
          <div className="flex flex-col md:flex-row md:justify-between md:items-center bg-teal-500 rounded-lg px-4 md:px-7 py-4 md:py-6 shadow-2xl -mt-10">
            <h2 className="text-white mb-4 text-xl md:text-2xl md:mr-4 md:mb-0">Category</h2>

            <Button
              onClick={() => setShowForm(true)}
              className="bg-orange-500 flex items-center px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300"
            >
              <BsPlus size={20} className="mr-2" />
              Add Category
            </Button>
          </div>

          <CardBody className="overflow-x-auto">
            <div className="max-h-[75vh] overflow-y-auto">
              <table className="min-w-full bg-transparent border-collapse">
                <thead>
                  <tr className="text-teal-500">
                    <th className="w-1/2 py-2 text-center text-xs md:text-sm">Category Name</th>
                    <th className="w-1/4 py-2 text-center text-xs md:text-sm">Created At</th>
                    <th className="w-1/4 py-2 text-center text-xs md:text-sm">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr key={String(index)} className="border-b border-gray-200">
                      <td className="py-2 text-center">{item?.name}</td>
                      <td className="py-2 text-center">{formatISODate(item?.created_at)}</td>
                      <td className="py-2 text-center">
                        <div className="flex items-center justify-center space-x-2">
                          <BsPencil
                            className="cursor-pointer text-brown-600"
                            size={20}
                            onClick={() => onEdit(item?.uuid)}
                          />
                          <BsTrash
                            className="cursor-pointer text-red-600"
                            size={20}
                            onClick={() => handleDelete(item?.uuid)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      ) : (
        <Modal onClose={() => setShowForm(false)} onSave={onAdd} />
      )}
    </>
  );
}
