import StatusCard from 'components/StatusCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getListTestimoni } from 'services/testimoni';
import TableTesti from './tableTesti';
import { useQuery } from 'react-query';

export default function TestimoniPage() {
    

        
    const { data: myGallery, isFetched, isSuccess, isLoading, } = useQuery(
        ["getGallery"],
        () =>  getListTestimoni(),
        {
          refetchOnWindowFocus: false,
          retry: 2
        }
      );

      console.log(myGallery, 'gael')

    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>
            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableTesti data={isLoading ? [] : myGallery?.data} />
                    </div>
                </div>
            </div>
          
        </>
    );
}
