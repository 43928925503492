import React, { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Team1 from 'assets/img/team-1-800x800.jpg';
import { swal } from '../../utils/alert'
import { BsPlus } from 'react-icons/bs';
import { deleteTesti } from 'services/testimoni';

const TableTesti = ({ data, handleAdd }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    id: '', // Assuming 'id' is the UUID field
  });

  const [result, setResult] = useState({
    error: false,
    success: false,
    successMessage: '',
    errorMessage: ''
  })

  const handleDelete = async (uuid) => {
    await deleteTesti(uuid).then(res => {
      if (res.statusCode == 200) {
        setResult({
          success: true,
          errorMessage: 'Data berhasil diubah'
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    }).catch(err => {
      setResult({
        error: true,
        errorMessage: err?.response?.data?.error?.message ?? 'Terjadi kesalahan'
      })

      setTimeout(() => {
        window.location.reload()
      }, 1000);
    })
  };


  return (
    <Card>
      <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
        <h2 className="text-white text-2xl w-{100} mr-4">
          Testimoni
        </h2>
        <a href={'/testimoni/create'} className="bg-orange-500 text-white rounded p-2 ml-2 mt-1">
          <BsPlus size={30}/>
        </a>
      </div>


      <CardBody>
        <div className="overflow-x-auto">
          {result.success && swal.success(result.successMessage)}
          {result.error && swal.error(result.errorMessage)}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Image
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Name
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Job
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={String(index)}>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    <div className="w-16 h-16 rounded-full border-2 border-white">
                      <img
                        alt='layanan-image'
                        className='rounded-full'
                        src={item?.url || Team1}
                      />
                    </div>
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    {item?.name}
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    {item?.job}
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    <a className='bg-light-blue-500 px-5 rounded-md py-2 text-white' href={`/testimoni/edit/${item?.uuid}`}>
                      Edit
                    </a>
                    <button className='bg-red-500 ml-1 px-5 rounded-md py-2 text-white' onClick={() => handleDelete(item.uuid)}>
                      Delete
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>

    
    </Card>
  );
};

export default TableTesti;
