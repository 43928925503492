import { Card, Input, Button, Textarea } from "@material-tailwind/react";
import { useState } from "react";
import { createArticle } from "../../services/article";
import { swal } from "../../utils/alert";
import Select from "react-select";
import { useQuery } from "react-query";
import { getListCategoryArticle } from "../../services/categoryArticle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { editorConfiguration } from "utils/enum";

export function FormArticle() {

    const [formData, setFormData] = useState({
        articleName: '',
        description: '',
        articleImage: null,
        articleFile: null,
        category_uuids: []
    });
    const [selectedService, setSelectedService] = useState([]);
    const [serviceUUIDs, setServiceUUIDs] = useState([]); // State untuk menyimpan array UUIDs
    const [count, setCount] = useState(0); // Menggunakan state count

    const [loading, setLoading] = useState(false)
    const handleInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleServices = (selectedOptions) => {
        setSelectedService(selectedOptions);
    
        // Extract UUIDs from selected options and set directly to formData
        const uuids = selectedOptions.map(option => option.value);
    
        // Directly update formData with the new uuids
        setFormData((prevFormData) => ({
            ...prevFormData,
            category_uuids: uuids,
        }));
    
        setCount(count + 1);
    };
    
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };
    const { data: categoryList, isFetched: categoryFetch, isSuccess: categorySuccess, isLoading: layananLoad } = useQuery(
        "getCategory",
        () => getListCategoryArticle(),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    );

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.articleImage) {
            swal.error('Gambar tidak boleh kosong');
            return; // Stop function execution if image is not provided
        }
    
        setLoading(true)
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.articleName);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('image', formData.articleImage);
        // formDataToSend.append('file', formData.articleFile);
        formDataToSend.append('category_uuids', JSON.stringify(formData.category_uuids))

        return await createArticle(formDataToSend)
        .then(res => {
            console.log(res,'ress')
            if(res.statusCode === 201){
                swal.success('Data berhasil terbuat')
                setTimeout(() => {
                    window.location.href = '/article'
                }, 1000);
            }
        }).catch(err => {
            console.log(err)
        })


    };


    return (
        <Card color="transparent" shadow={false}>
            <h2 color="blue-gray">
                Buat Artikel
            </h2>
            <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={handleSubmit}>
                <div className="mb-1 flex flex-col gap-6 w-full">
                    <p variant="h6" color="blue-gray" className="">
                        Article Name
                    </p>
                    <Input
                        size="lg"
                        placeholder="Enter article name"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleInputChange}
                        name="articleName" // Add name attribute
                    />
                    <p variant="h6" color="blue-gray" className="">
                        Description
                    </p>
                    <div className="w-full flex flex-col items-start py-2 mb-4">
                        <label htmlFor="layanan" className="block text-gray-700 font-bold mb-2">
                            Pilih Layanan:
                        </label>
                        <Select
                            isMulti
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="layanan"
                            placeholder="-- Pilih --"
                            options={
                                categorySuccess &&
                                categoryList?.data?.map((v) => ({
                                    label: v.name,
                                    value: v.uuid
                                }))
                            }
                            onChange={handleServices}
                            value={selectedService}
                            required
                        />
                    </div>

                    <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFormData((prevData) => ({
                            ...prevData,
                            description: data,
                          }));
                        }}
                      />
                    <p variant="h6" color="blue-gray">
                        Article Image
                    </p>
                    <Input
                        type="file"
                        size="lg"
                        accept=".jpg, .jpeg, .webp, png" // Accept only JPG and JPEG files
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleImage" // Add name attribute

                    />
                    {/* <p variant="h6" color="blue-gray">
                        Article File (PDF)
                    </p>
                    <Input
                        type="file"
                        size="lg"
                        accept=".pdf"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleFile" // Add name attribute
                    /> */}
                </div>
                <Button className="mt-6" fullWidth>
                    Add Article
                </Button>
            </form>
        </Card>
    );
}
