import { Card, Input, Button } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { updateArticle, getArticleDetail, getDetailArticle } from "../../services/article";
import { swal } from "../../utils/alert";
import Select from "react-select";
import { useQuery } from "react-query";
import { getListCategoryArticle } from "../../services/categoryArticle";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { editorConfiguration } from "utils/enum";
import { useParams, useHistory } from "react-router-dom";

export function EditArticle() {
    const { uuid } = useParams(); // Get article ID from route parameters
    console.log(uuid,)
    const history = useHistory(); // For redirecting after update
    const [formData, setFormData] = useState({
        uuid: '',
        articleName: '',
        description: '',
        articleImage: null,
        articleFile: null,
        category_uuids: []
    });
    const [selectedService, setSelectedService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentImage, setCurrentImage] = useState(""); // State for current image

    // Fetch category list
    const { data: categoryList, isFetched: categoryFetch, isSuccess: categorySuccess, isLoading: layananLoad } = useQuery(
        "getCategory",
        () => getListCategoryArticle(),
        {
            refetchOnWindowFocus: false,
            retry: 2,
        }
    );

    // Fetch article detail by ID
    useEffect(() => {
        const fetchArticleDetail = async () => {
            const response = await getDetailArticle(uuid);
            const article = response.data;

            setFormData({
                articleName: article.name,
                description: article.description,
                articleImage: null, // If you want to edit the image
                category_uuids: article.category_uuids,
                uuid: article.uuid
            });

            // Store the current image URL
            setCurrentImage(article.image);

            // Prepopulate the selected categories
            const preSelectedCategories = article.category.map((cat) => ({
                label: cat.name,
                value: cat.uuid
            }));
            setSelectedService(preSelectedCategories);
        };

        fetchArticleDetail();
    }, [uuid]);

    const handleInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleServices = (selectedOptions) => {
        setSelectedService(selectedOptions);
    
        const uuids = selectedOptions.map(option => option.value);
        setFormData((prevFormData) => ({
            ...prevFormData,
            category_uuids: uuids,
        }));
    };
    
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.articleName);
        formDataToSend.append('description', formData.description);
        if (formData.articleImage) {
            formDataToSend.append('image', formData.articleImage);
        }
        formDataToSend.append('category_uuids', JSON.stringify(formData.category_uuids));

        return await updateArticle(formData.uuid, formDataToSend)
        .then(res => {
            if (res.statusCode === 200) {
                swal.success('Artikel berhasil diperbarui');
                setTimeout(() => {
                    history.push('/article');
                }, 1000);
            }
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <Card color="transparent" shadow={false}>
            <h2 color="blue-gray">
                Edit Artikel
            </h2>
            <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={handleSubmit}>
                <div className="mb-1 flex flex-col gap-6 w-full">
                    <p variant="h6" color="blue-gray" className="">
                        Article Name
                    </p>
                    <Input
                        size="lg"
                        placeholder="Enter article name"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        value={formData.articleName}
                        onChange={handleInputChange}
                        name="articleName"
                    />
                    <p variant="h6" color="blue-gray" className="">
                        Description
                    </p>
                    <div className="w-full flex flex-col items-start py-2 mb-4">
                        <label htmlFor="layanan" className="block text-gray-700 font-bold mb-2">
                            Pilih Kategori:
                        </label>
                        <Select
                            isMulti
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="layanan"
                            placeholder="-- Pilih --"
                            options={
                                categorySuccess &&
                                categoryList?.data?.map((v) => ({
                                    label: v.name,
                                    value: v.uuid
                                }))
                            }
                            onChange={handleServices}
                            value={selectedService}
                            required
                        />
                    </div>

                    <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={formData.description}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormData((prevData) => ({
                                ...prevData,
                                description: data,
                            }));
                        }}
                    />
                    <p variant="h6" color="blue-gray">
                        Article Image
                    </p>
                    {currentImage && (
                        <div className="mb-4">
                            <p>Current Image:</p>
                            <img src={currentImage} alt="Current article" style={{ maxWidth: '100%', height: 'auto' }} />
                        </div>
                    )}
                    <Input
                        type="file"
                        size="lg"
                        accept=".jpg, .jpeg, .webp, png"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleImage"
                    />
                </div>
                <Button className="mt-6" fullWidth disabled={loading}>
                    {loading ? 'Updating...' : 'Update Article'}
                </Button>
            </form>
        </Card>
    );
}
