import React, { useState } from 'react';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Team1 from 'assets/img/team-1-800x800.jpg';
import { deleteLayanan, updateLayanan } from '../services/layananService';
import { swal } from '../utils/alert'
import { BsPlus } from 'react-icons/bs';

const CardTable = ({ data, handleAdd }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editFormData, setEditFormData] = useState({
    name: '',
    id: '', // Assuming 'id' is the UUID field
  });

  const [result, setResult] = useState({
    error: false,
    success: false,
    successMessage: '',
    errorMessage: ''
  })

  const handleDelete = async (uuid) => {
    await deleteLayanan(uuid).then(res => {
      if (res.statusCode == 200) {
        setResult({
          success: true,
          errorMessage: 'Data berhasil diubah'
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    }).catch(err => {
      setResult({
        error: true,
        errorMessage: err?.response?.data?.error?.message ?? 'Terjadi kesalahan'
      })

      setTimeout(() => {
        window.location.reload()
      }, 1000);
    })
  };

  const handleCloseUpdate = () => {
    setEditModalOpen(false)
  }

  const handleChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const { uuid, ...updatedData } = editFormData;
    await updateLayanan(uuid, updatedData).then(res => {
      if (res.statusCode == 200) {
        setResult({
          success: true,
          errorMessage: 'Data berhasil diubah'
        })

        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    }).catch(err => {
      setResult({
        error: true,
        errorMessage: err?.response?.data?.error?.message ?? 'Terjadi kesalahan'
      })

      setTimeout(() => {
        window.location.reload()
      }, 1000);
    })

    setEditFormData({
      name: '',
      id: '',
    });
    setEditModalOpen(false);
  };

  return (
    <Card>
      <div className="flex flex-row items-center justify-between bg-teal-500 rounded-lg px-7 h-24 shadow-2xl -mt-10">
        <h2 className="text-white text-2xl w-{100} mr-4">
          Layanan
        </h2>
        <a href={'/layanan/create'} className="bg-orange-500 text-white rounded p-2 ml-2 mt-1">
          <BsPlus size={30}/>
        </a>
      </div>


      <CardBody>
        <div className="overflow-x-auto">
          {result.success && swal.success(result.successMessage)}
          {result.error && swal.error(result.errorMessage)}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Profile
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Layanan
                </th>
                <th className="w-1/5 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={String(index)}>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    <div className="w-16 h-16 rounded-full border-2 border-white">
                      <img
                        alt='layanan-image'
                        className='rounded-full'
                        src={item?.image || Team1}
                      />
                    </div>
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    {item?.name}
                  </th>
                  <th className="w-1/5 border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap py-4 text-left">
                    <a className='bg-light-blue-500 px-5 rounded-md py-2 text-white' href={`/layanan/edit/${item?.uuid}`}>
                      Edit
                    </a>
                    <button className='bg-red-500 ml-1 px-5 rounded-md py-2 text-white' onClick={() => handleDelete(item.uuid)}>
                      Delete
                    </button>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>

      {/* Edit Modal */}
      {editModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="bg-white p-8 rounded-lg z-10">
            <h2 className="text-xl mb-4">Edit Layanan</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Name:
                </label>
                <input
                  name="name"
                  placeholder="Nama Service"
                  className="appearance-none bg-transparent border-b border-teal-500 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  aria-label="Nama Service"
                  value={editFormData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                {/* Add more form fields as needed */}
              </div>
              <button className='bg-orange-500 py-1 px-1 text-white rounded-md' type="button" onClick={handleSubmit}>
                Submit
              </button>
              <button className='bg-red-500 ml-1 py-1 px-1 text-white rounded-md' type="button" onClick={handleCloseUpdate}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </Card>
  );
};

export default CardTable;
