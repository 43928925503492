import endpoint from "../utils/endpoint";
import api from "../utils/api";
import { getToken } from "../utils/auth";

export const createTestimoni = async (payload) => {
    try {
        const response = await api.post(endpoint.createTesti, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+getToken()
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getListTestimoni = async () => {
    try {
        const response = await api.get(endpoint.listTestimoni);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateTesti = async (uuid, payload) => {
    try {
        const response = await api.put(endpoint.updateTesti + `${uuid}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteImageArticle = async (uuid, payload) => {
    try {
        const response = await api.delete(endpoint.deleteImagearticle + `/${uuid}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteTesti = async (uuid) => {
    try {
        const response = await api.delete(endpoint.deleteTesti + `/${uuid}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const detailTesti = async (uuid) => {
    try {
        const response = await api.get(endpoint.detailTesti + `${uuid}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};