import { useState } from 'react';
import { Button } from '@material-tailwind/react';

export default function ModalCategory({ onClose, onSave }) {
  const [categoryName, setCategoryName] = useState('');

  const handleSave = () => {
    onSave(categoryName);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h2 className="text-xl font-semibold mb-4">Add Category</h2>

        <div className="mb-4">
          <input
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
            placeholder="Category Name"
          />
        </div>

        <div className="flex justify-end space-x-4">
          <Button onClick={onClose} className="bg-gray-500">
            Cancel
          </Button>
          <Button onClick={handleSave} className="bg-teal-500">
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
