import endpoint from "../utils/endpoint";
import api from "../utils/api";
import { getToken } from "utils/auth";

export const addOrRemovePartner = async (payload) => {
    try {
        const response = await api.post(endpoint.addRemovePartner, payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getToken()
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getPartner = async (uuid) => {
    try {
        const response = await api.get(endpoint.getListPartner);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deletePartner = async (uuid) => {
    try {
        const response = await api.delete(endpoint.addRemovePartner + `delete/${uuid}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};