import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { formatISODate } from '../../utils/date';
import { BsPencil, BsPlus, BsTrash } from 'react-icons/bs'
import Swal from 'sweetalert2';
import { Button } from '@material-tailwind/react';
import { FormArticle } from './FormArticle';
import { useState } from 'react';
import { deleteArticle } from '../../services/article';
import { getStorage } from '../../utils/auth';

export default function TableArticle({ data }) {
  const isUser = getStorage()
  const [showForm, setShowForm] = useState(false)

  let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin'
  
  const handleDelete = (uuid) => {
    // Display SweetAlert for confirmation
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make the delete API call using the article's UUID
          // Replace the following line with your actual delete API call
          // await api.delete(`/articles/${uuid}`);
          
          // Optionally, you can handle the success response and update the UI accordingly
          // ...

          await deleteArticle(uuid)
          .then(res => {
          
            if(res.statusCode === 200){
              Swal.fire('Deleted!', 'Your article has been deleted.', 'success');
              setTimeout(() => {
                window.location.reload()
              }, 1500);
            }else{
              Swal.fire('Deleted!', 'Terjadi kesalahan delete artikel', 'error');
            }
          })
          // Display success message
        } catch (error) {
          // Handle errors, display an error message
          console.error('Error deleting article:', error);
          Swal.fire('Error', 'An error occurred while deleting the article.', 'error');
        }
      }
    });
  };

  return (
    <>
      {!showForm ?
        <Card>

      <div className="flex flex-col md:flex-row md:justify-between md:items-center bg-teal-500 rounded-lg px-4 md:px-7 py-4 md:py-6 shadow-2xl -mt-10">
        <h2 className="text-white mb-4 text-xl md:text-2xl md:mr-4 md:mb-0">
          Artikel
        </h2>

        <div className="flex space-x-4"> {/* Membuat dua tombol bersampingan dengan space */}
          <Button onClick={() => setShowForm(!showForm)} className="bg-orange-500 flex items-center px-4 py-2 rounded-md hover:bg-orange-600 transition duration-300">
            <BsPlus size={20} className="mr-2"/>
            Tambah Artikel
          </Button>
        </div>
      </div>


          <CardBody className="overflow-x-auto">
            <div className="max-h-[75vh] overflow-y-auto">
              <table className="min-w-full bg-transparent border-collapse">
                <thead className="md:table-header-group">
                  <tr className="text-teal-500">
                    <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Image</th>
                    <th className="w-1/5 md:w-2/5 py-2 md:px-3 text-center text-xs md:text-sm">Judul</th>
                    <th className="w-1/5 py-2 md:px-2 text-center text-xs md:text-sm">Tanggal terbuat</th>
                    {
                      admin ? (<></>) : 
                      (
                        <>
                          <th className="w-1/5 py-2 md:px-3 text-center text-xs md:text-sm">Action</th>
                        </>
                      )
                    }
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => (
                    <tr key={String(index)} className="border-b border-gray-200">
                      <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
                        {item?.image ? (
                          <img
                            alt="doctore-image"
                            className="rounded-full w-20 h-20 object-cover mx-auto"
                            src={item.image}
                          />
                        ) : (
                          <div className="w-20 h-20 rounded-full border-2 border-white mx-auto">
                            No Image
                          </div>
                        )}

                      </td>
                      <td className="w-1/5 md:w-2/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
                        {item?.name}
                      </td>
                      <td className="w-1/5 md:w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
                        {formatISODate(item?.created_at)}

                      </td>
                      {
                      admin ? ( <>
                        <td className="w-1/5 border-b md:border-b-0 border-gray-200 align-middle text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <Link to={'/article/' + item?.slug}><BsPencil className='mr-2' color='brown' size={20} /></Link>
                      <BsTrash className='hover:cursor-pointer' color='red' size={20} onClick={() => handleDelete(item?.uuid)} />
                    </div>
                  </td>
                    </>) : 
                      (
                       <></>
                      )
                    }
                    
                    </tr>
                  ))}
                </tbody>
              </table>


            </div>
          </CardBody>
        </Card>
        :

        <FormArticle />
      }
    </>
  );
}
