
const version = "/v1/"

const endpoint = {
    user_login: 'user' + version + 'login',
    check_profile: 'profile' + version + 'my-profile',
    user_register: 'profiles' + version + 'register',
    list_users: 'profile' + version + 'users',

    get_doctor: 'profile' + version + 'doctors/detail',
    list_doctors: 'profile' + version + 'doctors',
    register_doctor: 'user' + version + 'register-doctor',
    update_doctor: 'profile' + version + 'update-doctor',
    admin_update_doctor: 'profile' + version + 'admin/update-doctor',
    create_doctor_schedule: 'doctor/schedule' + version + 'create',
    get_doctor_schedule: 'doctor/schedule' + version + '?specialist_uuid=',


    list_layanan: 'master/services' + version,
    add_layanan: 'master/services' + version + 'create',
    update_layanan: 'master/services' + version,
    delete_layanan: 'master/services' + version,
    detail_layanan: 'master/services' + version,

    createGallery: 'master/gallery' + version + 'create',
    listGallery: 'master/gallery' + version + 'lists',
    detailGallery: 'master/gallery' + version,
    updateGallery: 'master/gallery' + version,
    deleteImageGallery: 'master/gallery' + version +'photo',
    deleteGallery: 'master/gallery' + version +'delete',

    createArticle: 'master/article' + version + 'create',
    listArticle: 'master/article' + version,
    listArticleDoctor: 'master/article' + version + 'users',
    detailarticle: 'master/article' + version + 'detail/edit',
    updatearticle: 'master/article' + version,
    deleteImagearticle: 'master/article' + version +'photo',
    deleteArticle: 'master/article' + version,

    createSetting: 'master/settings' + version + 'create',
    listSetting: 'master/settings' + version,
    detailSetting: 'master/settings' + version,
    updateSetting: 'master/settings' + version,

    createFriday: 'reservation' + version + 'friday',
    getFriday: 'reservation' + version + 'friday',

    createCategoryArticle: 'master/category-article' + version + 'create',
    updateCategoryArticle: 'master/category-article' + version + 'update',
    getListCategoryArticle: 'master/category-article' + version,

    getListPartner: 'master/partner' + version,
    addRemovePartner: 'master/partner' + version,

    createTesti: 'master/testimoni' + version,
    detailTesti: 'master/testimoni' + version,
    listTestimoni: 'master/testimoni' + version,
    deleteTesti: 'master/testimoni' + version + 'delete',
    updateTesti: 'master/testimoni' + version,
}

export default endpoint;
