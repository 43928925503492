import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Swal from 'sweetalert2';
import { updateGallery, deleteImageGallery } from '../../services/gallery';
import { swal } from '../../utils/alert';
import { BsPlus } from 'react-icons/bs';
import { getStorage } from '../../utils/auth';
import imageCompression from 'browser-image-compression';
import { deletePartner, getPartner } from '../../services/partner';
import { addOrRemovePartner } from '../../services/partner';

const AddPartner = (props) => {
  const uuid = props?.match?.params.uuid;
  const isUser = getStorage();
  let admin = String(isUser?.role?.[0]).toLocaleLowerCase() === 'master_admin';
  const [loading, setLoading] = useState(false);
  const [galleryData, setGalleryData] = useState({ images: [], newImages: [] });

  const { data: detailGallery, isFetched, isSuccess, isLoading, refetch } = useQuery(
    ['getPartner'],
    () => getPartner(),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onSuccess: (res) => {
        setGalleryData({
          images: res?.data?.map((photo) => ({
            url: photo.url,
            uuid: photo.uuid,
            file: null,
            isNew: false,
          })),
          newImages: [],
        });
      },
    }
  );

  const handleImageChange = async (e, index) => {
    const newFile = e.target.files[0];
    const compressedFile = await compressImage(newFile);

    setGalleryData((prevData) => ({
      ...prevData,
      newImages: [
        ...prevData.newImages,
        { file: compressedFile, url: URL.createObjectURL(compressedFile), isNew: true },
      ],
    }));
  };

  const compressImage = async (file) => {
    const options = { maxSizeMB: 2, maxWidthOrHeight: 800, useWebWorker: true };
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Error compressing image:', error);
      return file;
    }
  };

  const handleRemoveImage = (uuid, index, isNew = false) => {
    Swal.fire({
      title: "Hapus Foto",
      text: "Apakah anda yakin ingin menghapus foto ini?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Hapus",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isNew) {
          // Remove new, unsaved images directly from state
          setGalleryData((prevData) => {
            const newImages = [...prevData.newImages];
            newImages.splice(index, 1);
            return { ...prevData, newImages };
          });
        } else {
          // Remove existing, saved images via API
          try {
            await deletePartner(uuid);
            setGalleryData((prevData) => {
              const images = [...prevData.images];
              images.splice(index, 1);
              swal.success('Partner berhasil di hapus')
              return { ...prevData, images };
            });
          } catch (error) {
            console.error(error);
            swal.error(error?.response?.data?.message ?? 'Terjadi kesalahan');
          }
        }
      }
    });
  };

  const handleAddImage = () => {
    setGalleryData((prevData) => ({
      ...prevData,
      newImages: [...prevData.newImages, { file: null, url: '', isNew: true }],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    galleryData.newImages.forEach((image) => {
      if (image.file) formData.append('image', image.file);
    });

    try {
      const response = await addOrRemovePartner(formData);
      if (response.statusCode === 200) {
        swal.success('Data berhasil diubah');
        refetch();
      }
      setLoading(false);
    } catch (error) {
      swal.error(error?.response?.data?.message ?? 'Terjadi kesalahan');
      setLoading(false);
    }
  };

  return (
    <form className="container mx-2 max-w-full mt-8" onSubmit={handleSubmit}>
      <h1>Add / Remove Partner</h1>
      <br />

      <div className="mb-4 flex flex-wrap">
        <label htmlFor="images" className="block text-gray-700 font-bold mb-2 w-full">
          Images:
        </label>

        {galleryData.images.map((image, index) => (
          <div key={index} className="mb-2 flex items-center">
            <button
              type="button"
              onClick={() => handleRemoveImage(image.uuid, index)}
              className="bg-red-500 ml-2 text-white px-2 py-1 rounded"
            >
              Remove
            </button>
            <img src={image.url} alt={`Preview-${index}`} className="ml-4 w-16 h-16 object-cover rounded" />
          </div>
        ))}

        {galleryData.newImages.map((image, index) => (
          <div key={index} className="mb-2 flex items-center">
            <input type="file" onChange={(e) => handleImageChange(e, index)} className="mr-2" />
            <button
              type="button"
              onClick={() => handleRemoveImage(null, index, true)}
              className="bg-red-500 ml-2 text-white px-2 py-1 rounded"
            >
              Remove
            </button>
            {image.url && (
              <img src={image.url} alt={`New-Preview-${index}`} className="ml-4 w-16 h-16 object-cover rounded" />
            )}
          </div>
        ))}

        <button type="button" style={{ height: 30 }} onClick={handleAddImage} className="bg-blue-500 text-white ml-4 rounded">
          <BsPlus size={30} />
        </button>
      </div>

      <div>
        {loading ? (
          <button disabled className="bg-green-500 text-white px-4 py-2 rounded">
            Loading ...
          </button>
        ) : (
          <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
            Update
          </button>
        )}
      </div>
    </form>
  );
};

export default AddPartner;
