import { Card, Input, Button } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { updateArticle, getArticleDetail, getDetailArticle } from "../../services/article";
import { swal } from "../../utils/alert";
import { useParams, useHistory } from "react-router-dom";
import { detailTesti, updateTesti } from "../../services/testimoni";

export function EditTestimoni() {
    const { uuid } = useParams(); // Get article ID from route parameters
    const history = useHistory(); // For redirecting after update
    const [formData, setFormData] = useState({
        uuid: '',
        name: '',
        description: '',
        image: null,
        job: null,
    });
    const [loading, setLoading] = useState(false);
    const [currentImage, setCurrentImage] = useState(""); // State for current image


    // Fetch article detail by ID
    useEffect(() => {
        const fetchArticleDetail = async () => {
            const response = await detailTesti(uuid);
            const article = response.data;

            setFormData({
                name: article.name,
                description: article.description,
                job: article.job,
                image: null, // If you want to edit the image
                uuid: article.uuid
            });

            // Store the current image URL
            setCurrentImage(article.url);

        };

        fetchArticleDetail();
    }, [uuid]);

    const handleInputChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };
    
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files[0],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('job', formData.job);
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }

        return await updateTesti(formData.uuid, formDataToSend)
        .then(res => {
            if (res.statusCode === 200) {
                swal.success('Artikel berhasil diperbarui');
                setTimeout(() => {
                    history.push('/article');
                }, 1000);
            }
        })
        .catch(err => {
            console.error(err);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <Card color="transparent" shadow={false}>
            <h2 color="blue-gray">
                Edit Testimoni
            </h2>
            <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={handleSubmit}>
                <div className="mb-1 flex flex-col gap-6 w-full">
                    <p variant="h6" color="blue-gray" className="">
                        Name
                    </p>
                    <Input
                        size="lg"
                        placeholder="Enter article name"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        value={formData.name}
                        onChange={handleInputChange}
                        name="name"
                    />
                     <p variant="h6" color="blue-gray" className="">
                        Job
                    </p>
                    <Input
                        size="lg"
                        placeholder="Job"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        value={formData.job}
                        onChange={handleInputChange}
                        name="job"
                    />
                    <p variant="h6" color="blue-gray" className="">
                        Description
                    </p>
                    <Input
                        size="lg"
                        placeholder="Enter article name"
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        value={formData.description}
                        onChange={handleInputChange}
                        name="description"
                    />
                    
                    <p variant="h6" color="blue-gray">
                        Image
                    </p>
                    {currentImage && (
                        <div className="mb-4">
                            <p>Current Image:</p>
                            <img src={currentImage} alt="Current article" style={{ maxWidth: '100%', height: 'auto' }} />
                        </div>
                    )}
                    <Input
                        type="file"
                        size="lg"
                        accept=".jpg, .jpeg, .webp, png"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                        onChange={handleFileChange}
                        name="articleImage"
                    />
                </div>
                <Button className="mt-6" fullWidth disabled={loading}>
                    {loading ? 'Updating...' : 'Update Article'}
                </Button>
            </form>
        </Card>
    );
}
