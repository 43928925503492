import { useQuery } from 'react-query';
import { getListArticle, getListArticleDoctor } from '../../services/article.js';
import TableArticle from './TableArticle.js';
import { getStorage } from '../../utils/auth.js';
import TableCategory from './TableCateogryArticle.js';
import { createCategoryArticle, getListCategoryArticle, updateCategoryArticle } from '../../services/categoryArticle.js';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { swal } from 'utils/alert.js';

export default function ArticleCategory() {
    
    const user = getStorage()
    const { data: article, isFetched, isSuccess, isLoading, refetch, } = useQuery(
        ["getCategoryArticle"],
        () =>  String(user?.role?.[0]).toLowerCase() === 'master_admin' ? getListCategoryArticle(): null,
        {
          refetchOnWindowFocus: false,
          retry: 2
        }
    );

    const [categories, setCategories] = useState([]);
    const deleteCategory = async (uuid) => Promise.resolve({ status: 'success' });


    const handleAddCategory = async (name) => {
      const newCategory = { name };
      const result = await createCategoryArticle(newCategory);
      setCategories([...categories, result]);
      Swal.fire('Success!', 'Category added successfully.', 'success');
      refetch()
    };

    const handleEditCategory = async (uuid) => {
      const categoryToEdit = categories.find((category) => category.uuid === uuid);
      const { value: newName } = await Swal.fire({
        title: 'Edit Category',
        input: 'text',
        inputValue: categoryToEdit?.name,
        showCancelButton: true,
        confirmButtonText: 'Save',
        showLoaderOnConfirm: true,
        preConfirm: (name) => {
          if (!name) {
            Swal.showValidationMessage('Category name cannot be empty');
          }
          return name;
        },
      });
  
      if (newName) {
        const updatedCategory = await updateCategoryArticle(uuid, newName);
        setCategories(
          categories.map((category) => (category.uuid === uuid ? updatedCategory : category))
        );
        if(updatedCategory.statusCode === 200){
          Swal.fire('Success!', 'Category updated successfully.', 'success');
        }else{
          swal.error(updatedCategory?.response?.data?.error?.message)
        }
        refetch()
      }
    };
  
    // Handle deleting a category
    const handleDeleteCategory = async (uuid) => {
      const result = await deleteCategory(uuid);
      if (result.status === 'success') {
        setCategories(categories.filter((category) => category.uuid !== uuid));
        Swal.fire('Deleted!', 'Category deleted successfully.', 'success');
      } else {
        Swal.fire('Error!', 'Failed to delete category.', 'error');
      }
    };
  

    return (
        <>
            <div className="bg-orange-500 pt-14 pb-20 px-3 md:px-8 h-auto">
                <div className="container mx-auto max-w-full">
                </div>
            </div>

            <div className="px-3 md:px-8 h-auto -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 px-4 mb-16">
                        <TableCategory onEdit={handleEditCategory} onDelete={handleDeleteCategory} onAdd={handleAddCategory} data={article?.data} />
                    </div>
                </div>
            </div>
        </>
    );
}
